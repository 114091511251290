import React, { useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import joinClasses from '/src/functions/joinClasses'
import scrollTo from 'gatsby-plugin-smoothscroll'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const ImageVideoSwap = loadable(() => import('/src/components/Base/ImageVideoSwap'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const ToggleButton = loadable(() => import('/src/components/Basic/Buttons/ToggleButton'))

const AlternatingContent = ({
    backgroundColour,
    titleArea,
    itemsRepeater,
    addCtaOrExpansionToggle,
    buttonType,
    expandContent,
    callToAction,
    addSmallprint,
    smallprint,
    className,
    utm
}) => {
    const [isExpanded, setToggleExpansion] = useState(false)
    const [itemAmount, setItemAmount] = useState(Number(expandContent.initialItems) || '')
    const [toggleButtonText, setToggleButtonText] = useState(expandContent.moreItemsButtonText || '')

    const toggleContent = () => {
        setToggleExpansion((prev) => !prev)
        setItemAmount(!isExpanded ? itemsRepeater.length : Number(expandContent.initialItems))
        setToggleButtonText(!isExpanded ? expandContent.lessItemsButtonText : expandContent.moreItemsButtonText)
        if (isExpanded) {
            scrollTo('#alternating-content')
        }
    }

    const classes = ['c-alternating-content', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    const itemContent = (node, index) => {
        const isEven = index % 2 === 0

        return (
            <div key={index} className="row c-alternating-content__item">
                <div
                    className={`col-md-6 col-xl-5 ${
                        isEven ? 'offset-xl-1' : ''
                    } c-alternating-content__media-column c-alternating-content__media-column--${
                        isEven ? 'left' : 'right'
                    }`}
                >
                    <ImageVideoSwap
                        video={node.video}
                        image={node.image}
                        mediaType={node.mediaType}
                        className={'c-alternating-content__media'}
                    />
                </div>

                <div
                    className={`col-md-6 col-xl-5 ${
                        !isEven ? 'offset-xl-1' : ''
                    } c-alternating-content__content-column c-alternating-content__content-column--${
                        !isEven ? 'left' : 'right'
                    }`}
                >
                    {node.title && <h3 className="c-alternating-content__item-title">{node.title}</h3>}

                    {node.content && parse(node.content)}
                </div>
            </div>
        )
    }

    return (
        <Section
            id={'alternating-content'}
            variant={backgroundColour}
            titleArea={titleArea}
            className={concatenatedClasses}
        >
            {itemsRepeater &&
                (addCtaOrExpansionToggle && buttonType === 'toggle'
                    ? itemsRepeater.slice(0, itemAmount).map((node, index) => itemContent(node, index))
                    : itemsRepeater.map((node, index) => itemContent(node, index)))}

            {addCtaOrExpansionToggle && buttonType === 'toggle' ? (
                <CtaContainer align={'centered'}>
                    <ToggleButton toggleState={isExpanded} onClick={toggleContent}>
                        {toggleButtonText}
                    </ToggleButton>
                </CtaContainer>
            ) : addCtaOrExpansionToggle && buttonType === 'cta' ? (
                <CtaContainer align={'centered'}>
                    <ButtonLink data={callToAction} size={'lg'} icon={'arrow'} utm={utm} />
                </CtaContainer>
            ) : null}

            {addSmallprint && smallprint && (
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <ContentDisclaimer content={smallprint} />
                    </div>
                </div>
            )}
        </Section>
    )
}

AlternatingContent.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    itemsRepeater: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.string,
            mediaType: PropTypes.oneOf(['image', 'video']).isRequired,
            image: PropTypes.object,
            video: PropTypes.string
        })
    ),
    addCtaOrExpansionToggle: PropTypes.bool,
    buttonType: PropTypes.oneOf(['cta', 'toggle']),
    expandContent: PropTypes.shape({
        initialItems: PropTypes.string,
        moreItemsButtonText: PropTypes.string,
        lessItemsButtonText: PropTypes.string
    }),
    callToAction: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        target: PropTypes.string
    }),
    addSmallprint: PropTypes.bool,
    smallprint: PropTypes.string,
    className: PropTypes.string,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

AlternatingContent.defaultProps = {
    backgroundColour: 'light',
    titleArea: {
        addTitleArea: true,
        title: 'Section Title'
    },
    itemsRepeater: [
        {
            title: 'Item Title',
            content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis vitae lacus a bibendum. Fusce nisi turpis, feugiat sit amet dui sed, sollicitudin auctor diam. Integer imperdiet finibus velit quis gravida. Morbi convallis ex sed leo iaculis finibus.</p>`,
            mediaType: 'image'
        },
        {
            title: 'Item Title',
            content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis vitae lacus a bibendum. Fusce nisi turpis, feugiat sit amet dui sed, sollicitudin auctor diam. Integer imperdiet finibus velit quis gravida. Morbi convallis ex sed leo iaculis finibus.</p>`,
            mediaType: 'image'
        },
        {
            title: 'Item Title',
            content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam convallis vitae lacus a bibendum. Fusce nisi turpis, feugiat sit amet dui sed, sollicitudin auctor diam. Integer imperdiet finibus velit quis gravida. Morbi convallis ex sed leo iaculis finibus.</p>`,
            mediaType: 'image'
        }
    ],
    addCtaOrExpansionToggle: false,
    addSmallprint: false,
    smallprint: '<p>I am some smallprint</p>'
}

export default AlternatingContent

export const query = graphql`
    fragment AlternatingContentForPageBuilder on WpPage_Acfpagebuilder_Layouts_AlternatingContent {
        fieldGroupName
        backgroundColour
        addTitleArea
        title
        description
        itemsRepeater {
            title
            content
            mediaType
            image {
                id
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 800)
                    }
                }
                altText
                title
            }
            video
        }
        addCtaOrExpansionToggle
        buttonType
        expandContent {
            initialItems
            moreItemsButtonText
            lessItemsButtonText
        }
        callToAction {
            target
            title
            url
        }
        addSmallprint
        smallprint
    }
`
